@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Livvic:wght@100;200;300;400;500;600;700;900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@300;400;500;600;700;800&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600&amp;display=swap");
.thin-bg {
  background: #f9f9f9;
}

/*==========================================================================
Pack
========================================================================== */
body {
  font-family: "Mulish", sans-serif;
  color: #828187;
  font-size: 16px;
  line-height: 26px;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: all 0.3s ease-out 0s;
}

a:focus,
a:hover {
  text-decoration: none;
  color: inherit;
}

a,
button {
  outline: medium none;
  color: inherit;
  text-decoration: none;
}

a,
button,
i {
  text-decoration: none;
  color: inherit;
}

a:focus,
.button:focus {
  text-decoration: none;
  color: white;
  outline: none;
}

ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Livvic", sans-serif;
  color: #050707;
  padding: 0;
  margin: 0;
}

h2 {
  font-size: 45px;
  line-height: 55px;
  font-weight: 700;
  color: #050707;
  text-transform: capitalize;
}

@media (max-width: 767px) {
  h2 {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (max-width: 575px) {
  h2 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 359px) {
  h2 {
    font-size: 22px;
    line-height: 32px;
  }
}
h3 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #050707;
}

h4,
h5,
h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #050707;
}

.home__two {
  color: #373839;
  font-family: "Nunito", sans-serif;
}
.home__two p {
  text-transform: capitalize;
}
.home__two h1,
.home__two h2,
.home__two h3,
.home__two h4,
.home__two h5,
.home__two h6 {
  font-family: "Sarabun", sans-serif;
  font-weight: 700;
}
.home__two h2 {
  font-size: 48px;
  line-height: 58px;
}
.home__two h3 {
  font-size: 30px;
  line-height: 40px;
}
.home__two h4 {
  font-size: 24px;
  line-height: 34px;
}
.home__two h5 {
  font-size: 20px;
  line-height: 30px;
}
.home__two h6 {
  font-size: 18px;
  line-height: 28px;
}
@media (max-width: 1199px) {
  .home__two h2 {
    font-size: 38px;
    line-height: 48px;
  }
  .home__two h3 {
    font-size: 26px;
    line-height: 36px;
  }
}
@media (max-width: 991px) {
  .home__two h2 {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (max-width: 460px) {
  .home__two h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .home__two h3 {
    font-size: 22px;
    line-height: 32px;
  }
}
@media (max-width: 390px) {
  .home__two h2 {
    font-size: 27px;
    line-height: 37px;
  }
  .home__two h4 {
    font-size: 20px;
    line-height: 30px;
  }
}

/*==========================================================================
Form
========================================================================== */
button,
input[type=button],
input[type=reset],
input[type=submit] {
  border: 1px solid rgba(189, 188, 191, 0.35);
  border-color: transparent;
  border-radius: 0px;
  background: #42b6fe;
  color: white;
  line-height: 1;
  padding: 0.6em 1em 0.4em;
}

button:hover,
input[type=button]:hover,
input[type=reset]:hover,
input[type=submit]:hover {
  border-color: transparent;
}

button:active,
button:focus,
input[type=button]:active,
input[type=button]:focus,
input[type=reset]:active,
input[type=reset]:focus,
input[type=submit]:active,
input[type=submit]:focus {
  border-color: transparent;
}

input[type=text],
input[type=email],
input[type=url],
input[type=password],
input[type=search],
input[type=number],
input[type=tel],
input[type=range],
input[type=date],
input[type=month],
input[type=week],
input[type=time],
input[type=datetime],
input[type=datetime-local],
input[type=color],
textarea {
  color: #050707;
  border: 1px solid;
  border-radius: 0px;
  width: 100%;
  height: 60px;
  border: 1px solid rgba(189, 188, 191, 0.35);
  background: white;
  padding-left: 15px;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=number]:focus,
input[type=tel]:focus,
input[type=range]:focus,
input[type=date]:focus,
input[type=month]:focus,
input[type=week]:focus,
input[type=time]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=color]:focus,
textarea:focus {
  color: #050707;
  outline: none;
  box-shadow: none;
  border-color: #42b6fe;
}

select {
  border: 1px solid #BDBCBF;
}

textarea {
  width: 100%;
  height: 150px;
  padding-top: 15px;
}

button,
button:hover,
button:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.section-title {
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 55px;
}

.section-padding {
  padding: 130px 0px;
}

@media (max-width: 575px) {
  .section-padding {
    padding: 100px 0px;
  }
}
.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.o-hidden {
  overflow: hidden;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.p-relative {
  position: relative;
}

.t-center {
  text-align: center;
}

.t-left {
  text-align: left;
}

.t-right {
  text-align: right;
}

/*==========================================================================
Theme Btn CSS
========================================================================== */
.theme-banner-btn,
.price-btn,
.theme-btn,
.theme-btn2 {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: white;
  line-height: 60px;
  padding: 0 35px;
  font-weight: 700;
  transition: all 0.4s ease-out 0s;
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  text-transform: capitalize;
  z-index: 3;
}
.theme-banner-btn i::before,
.price-btn i::before,
.theme-btn i::before,
.theme-btn2 i::before {
  margin-left: 10px;
  top: 2px;
  position: relative;
}

.theme-banner-btn::before,
.price-btn::before,
.theme-btn::before,
.theme-btn2::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all 0.4s ease-out 0s;
  margin: 0 auto;
  z-index: -1;
  background: #050707;
  width: 10%;
  opacity: 0;
}

.theme-btn:hover {
  color: white;
}
.theme-btn:hover::before {
  background: #050707;
}

.price-btn {
  background: none;
  border: 2px solid #42b6fe;
  color: #050707;
}
.price-btn:hover {
  color: white;
}
.price-btn:hover::before {
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
}
.price-btn:focus {
  color: initial;
}

.theme-banner-btn {
  font-family: "Sarabun", sans-serif;
  font-weight: 600;
  background: #187AD4;
}
.theme-banner-btn:hover {
  color: #050707;
}
.theme-banner-btn:hover::before {
  background: white;
}

.theme-btn2 {
  font-family: "Sarabun", sans-serif;
  font-weight: 600;
  background: #187AD4;
}
.theme-btn2:hover {
  color: white;
}
.theme-btn2:hover::before {
  background: #050707;
}

.theme-banner-btn:hover::before,
.price-btn:hover::before,
.theme-btn:hover::before,
.theme-btn2:hover::before {
  width: 100%;
  opacity: 1;
}

/*==========================================================================
Preloader CSS
========================================================================== */
.spinner {
  width: 80px;
  height: 80px;
  position: relative;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.theme-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #050707;
  z-index: 9999999999;
}

.theme-loader .spinner {
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
}

.section-top {
  background: #E4F0FF;
  padding: 2px 25px;
  border-radius: 50px;
  font-family: "Livvic", sans-serif;
  font-weight: 600;
  color: #42b6fe;
  margin-bottom: 10px;
  display: inline-block;
}

.subtitle__two {
  font-family: "Sarabun", sans-serif;
  font-weight: 700;
  color: #187AD4;
  margin-bottom: 10px;
  display: inline-block;
}

/*==========================================================================
Scroll Top CSS
========================================================================== */
.scroll-top {
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  bottom: 1%;
  color: white;
  font-size: 20px;
  height: 50px;
  line-height: 55px;
  position: fixed;
  right: 1%;
  text-align: center;
  width: 50px;
  z-index: 9;
  cursor: pointer;
  display: none;
  border-radius: 50%;
}

/*==========================================================================
Video Animation CSS
========================================================================== */
@-webkit-keyframes animation {
  100% {
    left: 125%;
  }
}
@keyframes animation {
  100% {
    left: 125%;
  }
}
@-webkit-keyframes rotateY {
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes rotateY {
  100% {
    transform: rotateY(360deg);
  }
}
@-webkit-keyframes rotate {
  100% {
    transform: rotate(90deg);
  }
}
@keyframes rotate {
  100% {
    transform: rotate(90deg);
  }
}
.img__roll {
  -webkit-animation: rotation 20s infinite linear;
          animation: rotation 20s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
@-webkit-keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
.swiper-button-prev.slide-prev::after,
.swiper-button-next.slide-next::after {
  display: none;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1200px) {
  .custom-container {
    max-width: 1630px;
    margin: 0 auto;
  }
}
@media (min-width: 1200px) {
  .auto-container {
    max-width: 1400px;
    margin: 0 auto;
  }
}
.mt-0 {
  margin-top: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.pt-55 {
  padding-top: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.pt-65 {
  padding-top: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.pt-75 {
  padding-top: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.pt-85 {
  padding-top: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.pt-95 {
  padding-top: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.pr-100 {
  padding-right: 100px;
}

@media (max-width: 991px) {
  .lg-mb-0 {
    margin-bottom: 0px;
  }
  .lg-mt-0 {
    margin-top: 0px;
  }
  .lg-ml-0 {
    margin-left: 0px;
  }
  .lg-pt-0 {
    padding-top: 0px;
  }
  .lg-pb-0 {
    padding-bottom: 0px;
  }
  .lg-pl-0 {
    padding-left: 0px;
  }
  .lg-pr-0 {
    padding-right: 0px;
  }
  .lg-mb-5 {
    margin-bottom: 5px;
  }
  .lg-mt-5 {
    margin-top: 5px;
  }
  .lg-ml-5 {
    margin-left: 5px;
  }
  .lg-pt-5 {
    padding-top: 5px;
  }
  .lg-pb-5 {
    padding-bottom: 5px;
  }
  .lg-pl-5 {
    padding-left: 5px;
  }
  .lg-pr-5 {
    padding-right: 5px;
  }
  .lg-mb-10 {
    margin-bottom: 10px;
  }
  .lg-mt-10 {
    margin-top: 10px;
  }
  .lg-ml-10 {
    margin-left: 10px;
  }
  .lg-pt-10 {
    padding-top: 10px;
  }
  .lg-pb-10 {
    padding-bottom: 10px;
  }
  .lg-pl-10 {
    padding-left: 10px;
  }
  .lg-pr-10 {
    padding-right: 10px;
  }
  .lg-mb-15 {
    margin-bottom: 15px;
  }
  .lg-mt-15 {
    margin-top: 15px;
  }
  .lg-ml-15 {
    margin-left: 15px;
  }
  .lg-pt-15 {
    padding-top: 15px;
  }
  .lg-pb-15 {
    padding-bottom: 15px;
  }
  .lg-pl-15 {
    padding-left: 15px;
  }
  .lg-pr-15 {
    padding-right: 15px;
  }
  .lg-mb-20 {
    margin-bottom: 20px;
  }
  .lg-mt-20 {
    margin-top: 20px;
  }
  .lg-ml-20 {
    margin-left: 20px;
  }
  .lg-pt-20 {
    padding-top: 20px;
  }
  .lg-pb-20 {
    padding-bottom: 20px;
  }
  .lg-pl-20 {
    padding-left: 20px;
  }
  .lg-pr-20 {
    padding-right: 20px;
  }
  .lg-mb-25 {
    margin-bottom: 25px;
  }
  .lg-mt-25 {
    margin-top: 25px;
  }
  .lg-ml-25 {
    margin-left: 25px;
  }
  .lg-pt-25 {
    padding-top: 25px;
  }
  .lg-pb-25 {
    padding-bottom: 25px;
  }
  .lg-pl-25 {
    padding-left: 25px;
  }
  .lg-pr-25 {
    padding-right: 25px;
  }
  .lg-mb-30 {
    margin-bottom: 30px;
  }
  .lg-mt-30 {
    margin-top: 30px;
  }
  .lg-ml-30 {
    margin-left: 30px;
  }
  .lg-pt-30 {
    padding-top: 30px;
  }
  .lg-pb-30 {
    padding-bottom: 30px;
  }
  .lg-pl-30 {
    padding-left: 30px;
  }
  .lg-pr-30 {
    padding-right: 30px;
  }
  .lg-mb-35 {
    margin-bottom: 35px;
  }
  .lg-mt-35 {
    margin-top: 35px;
  }
  .lg-ml-35 {
    margin-left: 35px;
  }
  .lg-pt-35 {
    padding-top: 35px;
  }
  .lg-pb-35 {
    padding-bottom: 35px;
  }
  .lg-pl-35 {
    padding-left: 35px;
  }
  .lg-pr-35 {
    padding-right: 35px;
  }
  .lg-mb-40 {
    margin-bottom: 40px;
  }
  .lg-mt-40 {
    margin-top: 40px;
  }
  .lg-ml-40 {
    margin-left: 40px;
  }
  .lg-pt-40 {
    padding-top: 40px;
  }
  .lg-pb-40 {
    padding-bottom: 40px;
  }
  .lg-pl-40 {
    padding-left: 40px;
  }
  .lg-pr-40 {
    padding-right: 40px;
  }
  .lg-mb-45 {
    margin-bottom: 45px;
  }
  .lg-mt-45 {
    margin-top: 45px;
  }
  .lg-ml-45 {
    margin-left: 45px;
  }
  .lg-pt-45 {
    padding-top: 45px;
  }
  .lg-pb-45 {
    padding-bottom: 45px;
  }
  .lg-pl-45 {
    padding-left: 45px;
  }
  .lg-pr-45 {
    padding-right: 45px;
  }
  .lg-mb-50 {
    margin-bottom: 50px;
  }
  .lg-mt-50 {
    margin-top: 50px;
  }
  .lg-ml-50 {
    margin-left: 50px;
  }
  .lg-pt-50 {
    padding-top: 50px;
  }
  .lg-pb-50 {
    padding-bottom: 50px;
  }
  .lg-pl-50 {
    padding-left: 50px;
  }
  .lg-pr-50 {
    padding-right: 50px;
  }
  .lg-mb-55 {
    margin-bottom: 55px;
  }
  .lg-mt-55 {
    margin-top: 55px;
  }
  .lg-ml-55 {
    margin-left: 55px;
  }
  .lg-pt-55 {
    padding-top: 55px;
  }
  .lg-pb-55 {
    padding-bottom: 55px;
  }
  .lg-pl-55 {
    padding-left: 55px;
  }
  .lg-pr-55 {
    padding-right: 55px;
  }
  .lg-mb-60 {
    margin-bottom: 60px;
  }
  .lg-mt-60 {
    margin-top: 60px;
  }
  .lg-ml-60 {
    margin-left: 60px;
  }
  .lg-pt-60 {
    padding-top: 60px;
  }
  .lg-pb-60 {
    padding-bottom: 60px;
  }
  .lg-pl-60 {
    padding-left: 60px;
  }
  .lg-pr-60 {
    padding-right: 60px;
  }
  .lg-mb-65 {
    margin-bottom: 65px;
  }
  .lg-mt-65 {
    margin-top: 65px;
  }
  .lg-ml-65 {
    margin-left: 65px;
  }
  .lg-pt-65 {
    padding-top: 65px;
  }
  .lg-pb-65 {
    padding-bottom: 65px;
  }
  .lg-pl-65 {
    padding-left: 65px;
  }
  .lg-pr-65 {
    padding-right: 65px;
  }
  .lg-mb-70 {
    margin-bottom: 70px;
  }
  .lg-mt-70 {
    margin-top: 70px;
  }
  .lg-ml-70 {
    margin-left: 70px;
  }
  .lg-pt-70 {
    padding-top: 70px;
  }
  .lg-pb-70 {
    padding-bottom: 70px;
  }
  .lg-pl-70 {
    padding-left: 70px;
  }
  .lg-pr-70 {
    padding-right: 70px;
  }
}
@media (max-width: 767px) {
  .md-mb-0 {
    margin-bottom: 0px;
  }
  .md-pt-0 {
    padding-top: 0px;
  }
  .md-pb-0 {
    padding-bottom: 0px;
  }
  .md-pl-0 {
    padding-left: 0px;
  }
  .md-pr-0 {
    padding-right: 0px;
  }
  .md-mb-5 {
    margin-bottom: 5px;
  }
  .md-pt-5 {
    padding-top: 5px;
  }
  .md-pb-5 {
    padding-bottom: 5px;
  }
  .md-pl-5 {
    padding-left: 5px;
  }
  .md-pr-5 {
    padding-right: 5px;
  }
  .md-mb-10 {
    margin-bottom: 10px;
  }
  .md-pt-10 {
    padding-top: 10px;
  }
  .md-pb-10 {
    padding-bottom: 10px;
  }
  .md-pl-10 {
    padding-left: 10px;
  }
  .md-pr-10 {
    padding-right: 10px;
  }
  .md-mb-15 {
    margin-bottom: 15px;
  }
  .md-pt-15 {
    padding-top: 15px;
  }
  .md-pb-15 {
    padding-bottom: 15px;
  }
  .md-pl-15 {
    padding-left: 15px;
  }
  .md-pr-15 {
    padding-right: 15px;
  }
  .md-mb-20 {
    margin-bottom: 20px;
  }
  .md-pt-20 {
    padding-top: 20px;
  }
  .md-pb-20 {
    padding-bottom: 20px;
  }
  .md-pl-20 {
    padding-left: 20px;
  }
  .md-pr-20 {
    padding-right: 20px;
  }
  .md-mb-25 {
    margin-bottom: 25px;
  }
  .md-pt-25 {
    padding-top: 25px;
  }
  .md-pb-25 {
    padding-bottom: 25px;
  }
  .md-pl-25 {
    padding-left: 25px;
  }
  .md-pr-25 {
    padding-right: 25px;
  }
  .md-mb-30 {
    margin-bottom: 30px;
  }
  .md-pt-30 {
    padding-top: 30px;
  }
  .md-pb-30 {
    padding-bottom: 30px;
  }
  .md-pl-30 {
    padding-left: 30px;
  }
  .md-pr-30 {
    padding-right: 30px;
  }
  .md-mb-35 {
    margin-bottom: 35px;
  }
  .md-pt-35 {
    padding-top: 35px;
  }
  .md-pb-35 {
    padding-bottom: 35px;
  }
  .md-pl-35 {
    padding-left: 35px;
  }
  .md-pr-35 {
    padding-right: 35px;
  }
  .md-mb-40 {
    margin-bottom: 40px;
  }
  .md-pt-40 {
    padding-top: 40px;
  }
  .md-pb-40 {
    padding-bottom: 40px;
  }
  .md-pl-40 {
    padding-left: 40px;
  }
  .md-pr-40 {
    padding-right: 40px;
  }
  .md-mb-45 {
    margin-bottom: 45px;
  }
  .md-pt-45 {
    padding-top: 45px;
  }
  .md-pb-45 {
    padding-bottom: 45px;
  }
  .md-pl-45 {
    padding-left: 45px;
  }
  .md-pr-45 {
    padding-right: 45px;
  }
  .md-t-left {
    text-align: left;
  }
}
@media (max-width: 575px) {
  .sm-mb-0 {
    margin-bottom: 0px;
  }
  .sm-mt-0 {
    margin-top: 0px;
  }
  .sm-pt-0 {
    padding-top: 0px;
  }
  .sm-pb-0 {
    padding-bottom: 0px;
  }
  .sm-pl-0 {
    padding-left: 0px;
  }
  .sm-pr-0 {
    padding-right: 0px;
  }
  .sm-mb-5 {
    margin-bottom: 5px;
  }
  .sm-mt-5 {
    margin-top: 5px;
  }
  .sm-pt-5 {
    padding-top: 5px;
  }
  .sm-pb-5 {
    padding-bottom: 5px;
  }
  .sm-pl-5 {
    padding-left: 5px;
  }
  .sm-pr-5 {
    padding-right: 5px;
  }
  .sm-mb-10 {
    margin-bottom: 10px;
  }
  .sm-mt-10 {
    margin-top: 10px;
  }
  .sm-pt-10 {
    padding-top: 10px;
  }
  .sm-pb-10 {
    padding-bottom: 10px;
  }
  .sm-pl-10 {
    padding-left: 10px;
  }
  .sm-pr-10 {
    padding-right: 10px;
  }
  .sm-mb-15 {
    margin-bottom: 15px;
  }
  .sm-mt-15 {
    margin-top: 15px;
  }
  .sm-pt-15 {
    padding-top: 15px;
  }
  .sm-pb-15 {
    padding-bottom: 15px;
  }
  .sm-pl-15 {
    padding-left: 15px;
  }
  .sm-pr-15 {
    padding-right: 15px;
  }
  .sm-mb-20 {
    margin-bottom: 20px;
  }
  .sm-mt-20 {
    margin-top: 20px;
  }
  .sm-pt-20 {
    padding-top: 20px;
  }
  .sm-pb-20 {
    padding-bottom: 20px;
  }
  .sm-pl-20 {
    padding-left: 20px;
  }
  .sm-pr-20 {
    padding-right: 20px;
  }
  .sm-mb-25 {
    margin-bottom: 25px;
  }
  .sm-mt-25 {
    margin-top: 25px;
  }
  .sm-pt-25 {
    padding-top: 25px;
  }
  .sm-pb-25 {
    padding-bottom: 25px;
  }
  .sm-pl-25 {
    padding-left: 25px;
  }
  .sm-pr-25 {
    padding-right: 25px;
  }
  .sm-mb-30 {
    margin-bottom: 30px;
  }
  .sm-mt-30 {
    margin-top: 30px;
  }
  .sm-pt-30 {
    padding-top: 30px;
  }
  .sm-pb-30 {
    padding-bottom: 30px;
  }
  .sm-pl-30 {
    padding-left: 30px;
  }
  .sm-pr-30 {
    padding-right: 30px;
  }
  .sm-mb-35 {
    margin-bottom: 35px;
  }
  .sm-mt-35 {
    margin-top: 35px;
  }
  .sm-pt-35 {
    padding-top: 35px;
  }
  .sm-pb-35 {
    padding-bottom: 35px;
  }
  .sm-pl-35 {
    padding-left: 35px;
  }
  .sm-pr-35 {
    padding-right: 35px;
  }
  .sm-mb-40 {
    margin-bottom: 40px;
  }
  .sm-mt-40 {
    margin-top: 40px;
  }
  .sm-pt-40 {
    padding-top: 40px;
  }
  .sm-pb-40 {
    padding-bottom: 40px;
  }
  .sm-pl-40 {
    padding-left: 40px;
  }
  .sm-pr-40 {
    padding-right: 40px;
  }
  .sm-mb-45 {
    margin-bottom: 45px;
  }
  .sm-mt-45 {
    margin-top: 45px;
  }
  .sm-pt-45 {
    padding-top: 45px;
  }
  .sm-pb-45 {
    padding-bottom: 45px;
  }
  .sm-pl-45 {
    padding-left: 45px;
  }
  .sm-pr-45 {
    padding-right: 45px;
  }
}
/*==========================================================================
About
========================================================================== */
.about__area-left {
  max-width: 470px;
  margin: 0 auto;
}
.about__area-left-content {
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  display: inline-flex;
  align-items: center;
  padding: 20px 30px;
  position: absolute;
  bottom: 80px;
  left: 15px;
}
.about__area-left-content h6 {
  color: white;
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  margin-right: 5px;
}
.about__area-left-content p {
  color: white;
}
.about__area-right-title h2 {
  max-width: 500px;
  margin-bottom: 20px;
}
.about__area-right-title p {
  max-width: 490px;
}
.about__area-right-list {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(189, 188, 191, 0.35);
  padding: 30px 0;
  margin-bottom: 30px;
}
.about__area-right-list-item-icon {
  margin-bottom: 15px;
}
.about__area-right-list-item-icon img {
  max-width: 65px;
}
.about__area-right-list-item.center {
  margin: 0 22px;
}
.about__area-right-contact {
  display: flex;
}
.about__area-right-contact-item-call {
  display: flex;
  margin-left: 40px;
  align-items: end;
}
.about__area-right-contact-item-call-icon i::before {
  font-size: 35px;
  color: #42b6fe;
  margin-right: 20px;
}
.about__area-right-contact-item-call-content p a {
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  color: #000;
  transition: all 0.4s ease-out 0s;
}
.about__area-right-contact-item-call-content p a:hover {
  color: #42b6fe;
}

@media (max-width: 420px) {
  .about__area-right-list {
    display: block;
  }
  .about__area-right-list-item.center {
    margin: 30px 0;
  }
  .about__area-right-contact {
    display: block;
  }
  .about__area-right-contact-item-call {
    margin-left: 0;
    margin-top: 20px;
  }
}
/*==========================================================================
Banner
========================================================================== */
.banner__area {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 180px 0;
}
.banner__area-title {
  max-width: 950px;
}
.banner__area-title h2 {
  color: #050707;
  font-size: 90px;
  line-height: 115px;
  font-weight: 800;
  margin-bottom: 45px;
  text-transform: capitalize;
}
.banner__area-title span {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-transform: capitalize;
  color: #42b6fe;
  display: inline-block;
  margin-bottom: 15px;
}

@media (max-width: 991px) {
  .banner__area-title h2 {
    font-size: 70px;
    line-height: 85px;
  }
  .banner__area-title span {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
}
@media (max-width: 767px) {
  .banner__area {
    padding: 90px 0;
  }
  .banner__area-title h2 {
    font-size: 50px;
    line-height: 65px;
  }
}
@media (max-width: 430px) {
  .banner__area-title h2 {
    font-size: 38px;
    line-height: 53px;
  }
}
/*==========================================================================
Blog
========================================================================== */
.blog__area-item {
  position: relative;
}
.blog__area-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.5s ease-out 0s;
  width: 0;
  height: 2px;
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  opacity: 0;
}
.blog__area-item:hover::after {
  width: 100%;
  opacity: 1;
}
.blog__area-item:hover .blog__area-image img {
  transform: scale(1.1);
}
.blog__area-item:hover .blog__area-image::before {
  -webkit-animation: animation 0.85s;
          animation: animation 0.85s;
}
.blog__area-image {
  position: relative;
  overflow: hidden;
}
.blog__area-image::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 20%;
  height: 100%;
  transform: skewX(-25deg);
  background: rgba(255, 255, 255, 0.246);
}
.blog__area-date {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  width: 70px;
  height: 65px;
  text-align: center;
  padding: 11px 0;
}
.blog__area-date h3 {
  color: white;
  font-size: 20px;
  line-height: 20px;
}
.blog__area-date p {
  color: white;
  font-size: 16px;
  line-height: 26px;
}
.blog__area-content {
  padding: 25px 35px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
}
.blog__area-content ul li {
  display: inline-block;
  font-weight: 500;
  font-family: "Livvic", sans-serif;
  margin-bottom: 10px;
  margin-right: 20px;
}
.blog__area-content ul li i {
  color: #42b6fe;
  margin-right: 5px;
}
.blog__area-content ul li:last-child {
  margin-right: 0;
}
.blog__area-content a {
  text-transform: capitalize;
  font-weight: 700;
  font-family: "Mulish", sans-serif;
  color: #050707;
  transition: all 0.3s ease-out 0s;
  display: inline-block;
}
.blog__area-content a:hover {
  color: #42b6fe;
}
.blog__area-content h3 a {
  color: #050707;
  transition: all 0.4s ease-out 0s;
  text-transform: none;
  font-weight: 700;
  font-family: "Livvic", sans-serif;
  margin-bottom: 10px;
}
.blog__area-content h3 a:hover {
  color: #42b6fe;
}
.blog__area-content p {
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(189, 188, 191, 0.35);
  margin-bottom: 25px;
}
.blog__area-pagination ul li:last-child {
  margin: 0;
}
.blog__area-pagination ul li {
  display: inline-block;
  margin-right: 15px;
}
.blog__area-pagination ul li .active {
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  color: white;
}
.blog__area-pagination ul li a {
  color: #050707;
  background: #f9f9f9;
  display: block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  font-weight: 600;
}
.blog__area-pagination ul li a:hover {
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  color: white;
}

/*==========================================================================
All Sidebar
========================================================================== */
.all__sidebar-item {
  background: white;
  padding: 40px 30px;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.08);
}
.all__sidebar-item-search form {
  position: relative;
}
.all__sidebar-item-search form button {
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  width: 60px;
  height: 60px;
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  display: inline-block;
}
.all__sidebar-item-category ul li {
  position: relative;
  list-style: none;
}
.all__sidebar-item-category ul li::after {
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 100%;
  top: 0;
  bottom: 0;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}
.all__sidebar-item-category ul li a {
  display: block;
  color: #050707;
  font-weight: 500;
  border: 1px solid rgba(189, 188, 191, 0.35);
  margin-bottom: 15px;
  padding: 16px 20px;
  transition: all 0.4s ease-out 0s;
  position: relative;
  z-index: 2;
}
.all__sidebar-item-category ul li a i {
  float: right;
  position: relative;
  z-index: 1;
}
.all__sidebar-item-category ul li a i::before {
  color: white;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  text-align: center;
  font-size: 14px;
  position: relative;
  top: -2px;
  transition: all 0.4s ease-out 0s;
}
.all__sidebar-item-category ul li:last-child a {
  margin: 0;
}
.all__sidebar-item-category ul li:hover::after {
  width: 100%;
}
.all__sidebar-item-category ul li:hover a {
  color: white;
}
.all__sidebar-item-category ul li:hover a i::before {
  background: white;
  color: #42b6fe;
}
.all__sidebar-item-help-icon {
  color: #42b6fe;
  font-size: 40px;
  margin-right: 15px;
}
.all__sidebar-item-help-content span {
  text-transform: capitalize;
  font-family: "Livvic", sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}
.all__sidebar-item-help-content a p {
  color: #050707;
  font-weight: 600;
}
.all__sidebar-item-hours {
  padding: 40px 30px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.all__sidebar-item-hours::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #050707;
  opacity: 0.9;
}
.all__sidebar-item-hours h3 {
  color: white;
  position: relative;
  z-index: 2;
}
.all__sidebar-item-hours ul {
  position: relative;
  z-index: 2;
}
.all__sidebar-item-hours ul li {
  color: white;
  padding: 5px;
}
.all__sidebar-item-hours ul li p {
  float: right;
  color: white;
}
.all__sidebar-item-post-item {
  display: flex;
  align-items: center;
}
.all__sidebar-item-post-item-thumb {
  max-width: 30%;
}
.all__sidebar-item-post-item-content {
  max-width: 70%;
}
.all__sidebar-item-post-item-content h6 a {
  transition: all 0.4s ease-in-out;
  color: #050707;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  margin-bottom: 5px;
}
.all__sidebar-item-post-item-content h6 a:hover {
  color: #42b6fe;
}
.all__sidebar-item-post-item-content span i::before {
  color: #42b6fe;
  margin-right: 10px;
}
.all__sidebar-item-post-item-content span a {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
  color: #828187;
}
.all__sidebar-item-post-item-content span a:hover {
  color: #42b6fe;
}
.all__sidebar-item-tag a {
  display: inline-block;
  width: auto;
  line-height: 35px;
  color: #828187;
  padding: 0 20px;
  border: 1px solid rgba(189, 188, 191, 0.35);
  margin-right: 8px;
  margin-bottom: 12px;
  transition: all 0.4s ease-out 0s;
  font-size: 14px;
  font-weight: 500;
}
.all__sidebar-item-tag a:hover {
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  color: white;
  border-color: #42b6fe;
}
.all__sidebar-item-gallery-item {
  overflow: hidden;
}
.all__sidebar-item-gallery-item img {
  border-radius: 5px;
  transform: scale(1);
  transition: all 0.4s ease-in-out;
}
.all__sidebar-item-gallery-item:hover img {
  transform: scale(1.1);
}

@media (max-width: 395px) {
  .blog__area-content {
    padding: 25px;
  }
  .blog__area-content h3 a {
    font-size: 20px;
    line-height: 30px;
  }
}
@media (max-width: 355px) {
  .all__sidebar-item {
    padding: 35px 25px;
  }
}
@media (max-width: 360px) {
  .blog__area-pagination ul li {
    margin-right: 6px;
  }
}
@media (max-width: 370px) {
  .blog__area-item-content {
    padding: 30px 25px;
  }
  .blog__area-item-content-meta ul li {
    font-size: 13px;
    margin-right: 5px;
  }
  .blog__area-item-content-meta ul li i::before {
    color: #42b6fe;
    margin-right: 4px;
    font-size: 16px;
  }
  .blog__area-item-content h3 a {
    font-size: 25px;
    line-height: 35px;
  }
}
/*==========================================================================
Blog Standard
========================================================================== */
.blog__standard-left-item-content {
  padding: 40px 30px;
  background: #f9f9f9;
}
.blog__standard-left-item-content-meta {
  margin-bottom: 25px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(189, 188, 191, 0.35);
}
.blog__standard-left-item-content-meta ul li {
  display: inline-block;
  margin-right: 30px;
  list-style: none;
}
.blog__standard-left-item-content-meta ul li:last-child {
  margin: 0;
}
.blog__standard-left-item-content-meta ul li a {
  color: #828187;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
}
.blog__standard-left-item-content-meta ul li a i::before {
  color: #42b6fe;
  margin-right: 10px;
}
.blog__standard-left-item-content-meta ul li a:hover {
  color: #42b6fe;
}
.blog__standard-left-item-content h2 a {
  color: #050707;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  margin: 0;
  font-size: 35px;
  line-height: 45px;
}
.blog__standard-left-item-content h2 a:hover {
  color: #42b6fe;
}
.blog__standard-left-item-content-btn {
  margin-top: 15px;
}
.blog__standard-left-item-content-btn a {
  text-transform: capitalize;
  font-weight: 700;
  font-family: "Mulish", sans-serif;
  color: #41404A;
  transition: all 0.4s ease-out 0s;
  display: inline-block;
}
.blog__standard-left-item-content-btn a:hover {
  color: #42b6fe;
}

@media (max-width: 767px) {
  .blog__standard-left-item-content h2 a {
    font-size: 35px;
    line-height: 47px;
  }
}
@media (max-width: 510px) {
  .blog__standard-left-item-content-meta ul li {
    margin-right: 20px;
    font-size: 14px;
  }
  .blog__standard-left-item-content h2 a {
    font-size: 30px;
    line-height: 42px;
  }
}
@media (max-width: 450px) {
  .blog__standard-left-item-content-meta ul li {
    margin-right: 10px;
  }
  .blog__standard-left-item-content h2 a {
    font-size: 25px;
    line-height: 37px;
  }
}
@media (max-width: 390px) {
  .blog__standard-left-item-content {
    padding: 35px 25px;
  }
  .blog__standard-left-item-content h2 a {
    font-size: 20px;
    line-height: 32px;
  }
}
/*==========================================================================
Blog Details
========================================================================== */
.blog__details-left-content-meta {
  padding: 25px 0;
  border-bottom: 1px solid rgba(189, 188, 191, 0.35);
}
.blog__details-left-content-meta ul li {
  display: inline-block;
  margin-right: 20px;
}
.blog__details-left-content-meta ul li i::before {
  color: #42b6fe;
  margin-right: 10px;
}
.blog__details-left-content-meta ul li a {
  color: #828187;
  transition: all 0.3s ease-out 0s;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}
.blog__details-left-content-meta ul li a:hover {
  color: #42b6fe;
}
.blog__details-left-content-meta ul li:last-child {
  margin: 0;
}
.blog__details-left-content-box {
  background: #f9f9f9;
  padding: 30px 40px;
}
.blog__details-left-content-box h6 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  font-style: italic;
}
.blog__details-left-content-box span {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  display: inline-block;
  text-transform: capitalize;
  padding-left: 40px;
  position: relative;
}
.blog__details-left-content-box span::before {
  position: absolute;
  content: "";
  width: 30px;
  height: 3px;
  left: 0;
  top: 10px;
  background: #42b6fe;
}
.blog__details-left-related {
  margin-bottom: 45px;
  padding-bottom: 45px;
  border-bottom: 1px solid rgba(189, 188, 191, 0.35);
}
.blog__details-left-related p {
  font-weight: 700;
  margin-right: 20px;
  color: #050707;
}
.blog__details-left-related-tag {
  display: inline-flex;
  align-items: center;
}
.blog__details-left-related-tag a {
  display: inline-block;
  width: auto;
  line-height: 35px;
  color: #828187;
  padding: 0 20px;
  border: 1px solid rgba(189, 188, 191, 0.35);
  margin-right: 8px;
  transition: all 0.4s ease-out 0s;
  font-size: 14px;
  font-weight: 500;
}
.blog__details-left-related-tag a:hover {
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  color: white;
  border-color: #42b6fe;
}
.blog__details-left-related-share {
  display: inline-flex;
  align-items: center;
}
.blog__details-left-related-share ul li {
  display: inline-block;
  margin-right: 8px;
  list-style: none;
}
.blog__details-left-related-share ul li:last-child {
  margin: 0;
}
.blog__details-left-related-share ul li a i::before {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid rgba(189, 188, 191, 0.35);
  display: inline-block;
  color: #050707;
  border-radius: 50%;
  transition: all 0.4s ease-out 0s;
}
.blog__details-left-related-share ul li a i:hover::before {
  background: #42b6fe;
  border-color: #42b6fe;
  color: white;
}
.blog__details-left-post-item {
  background: #f9f9f9;
  padding: 40px 30px;
}
.blog__details-left-post-item-icon {
  margin-bottom: 5px;
}
.blog__details-left-post-item-icon a {
  color: #42b6fe;
  font-weight: 600;
}
.blog__details-left-post-item-icon a i::before {
  position: relative;
  top: 2px;
}
.blog__details-left-post-item p {
  color: #050707;
  font-weight: 700;
}
.blog__details-left-post-author {
  padding: 30px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.blog__details-left-post-author-image {
  float: left;
}
.blog__details-left-post-author-image img {
  width: 150px;
  height: 150px;
}
.blog__details-left-post-author-content {
  max-width: 490px;
  overflow: hidden;
  padding-left: 30px;
}
.blog__details-left-post-author-content h4 {
  margin-bottom: 10px;
}
.blog__details-left-comment {
  margin-bottom: 45px;
  padding-bottom: 45px;
  border-bottom: 1px solid rgba(189, 188, 191, 0.35);
}
.blog__details-left-comment-item-comment-image {
  float: left;
}
.blog__details-left-comment-item-comment-image img {
  width: 80px;
  height: 80px;
}
.blog__details-left-comment-item-comment-content {
  max-width: 660px;
  padding-left: 30px;
  overflow: hidden;
}
.blog__details-left-comment-item-comment-content span {
  font-size: 14px;
  margin-bottom: 15px;
  display: inline-block;
}
.blog__details-left-comment-item-comment-content a {
  color: #42b6fe;
  font-weight: 700;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;
}
.blog__details-left-comment-item-comment-content a i::before {
  margin-right: 15px;
}
.blog__details-left-comment-item-comment-content a:hover {
  color: #050707;
}

@media (max-width: 575px) {
  .blog__details-left-meta ul li {
    margin-right: 20px;
  }
  .blog__details-left-related p {
    margin-right: 10px;
  }
  .blog__details-left-related-tag a {
    padding: 0 18px;
    margin-right: 5px;
  }
  .blog__details-left-post-author-image {
    float: initial;
    margin-bottom: 20px;
  }
  .blog__details-left-post-author-content {
    padding-left: 0;
  }
  .blog__details-left-comment-item {
    margin-left: 0;
  }
  .blog__details-left-comment-item-comment-image {
    float: initial;
    margin-bottom: 20px;
  }
  .blog__details-left-comment-item-comment-content {
    padding-left: 0;
  }
}
@media (max-width: 1199px) {
  .blog__two-item-content {
    bottom: 50px;
    padding: 0 30px;
  }
  .blog__two-item:hover .blog__two-item-content {
    bottom: 30px;
  }
}
@media (max-width: 405px) {
  .blog__two-item-content h3 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
  }
  .blog__two-item-content-meta {
    margin-bottom: 10px;
  }
  .blog__two-item-content-meta ul li span {
    padding: 0 10px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
  }
}
/*==========================================================================
Choose
========================================================================== */
.choose__ua {
  background-color: #f9f9f9;
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  position: relative;
}
.choose__ua-left {
  position: absolute;
  background-position: center left;
  background-size: cover;
  background-repeat: repeat;
  left: 0;
  top: 0;
  bottom: 0;
  width: 47%;
  height: 100%;
}
.choose__ua-right {
  max-width: 560px;
}
.choose__ua-right-list-icon {
  text-align: center;
  background: white;
  padding: 45px 30px;
}
.choose__ua-right-list-icon i::before {
  font-size: 45px;
  color: #42b6fe;
  margin-bottom: 30px;
}

.choose__ua-right-list-icon-hover {
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
}
.choose__ua-right-list-icon-hover i::before {
  color: white;
}
.choose__ua-right-list-icon-hover h4 {
  color: white;
}

@media (max-width: 991px) {
  .choose__ua-left {
    display: none;
  }
}
/*==========================================================================
Contact
========================================================================== */
.contact__list-item {
  padding: 60px 40px;
  transition: all 0.3s ease-out 0s;
  box-shadow: 0 0 90px rgba(0, 0, 0, 0.08);
  text-align: center;
}
.contact__list-item:hover {
  background: #42b6fe;
}
.contact__list-item:hover p {
  color: white;
}
.contact__list-item:hover p a {
  color: white;
}
.contact__list-item-icon {
  width: 90px;
  height: 90px;
  line-height: 90px;
  display: inline-block;
  border-radius: 50%;
  background: #f9f9f9;
  text-align: center;
  margin-bottom: 30px;
  transition: all 0.3s ease-out 0s;
}
.contact__list-item-icon img {
  max-width: 45px;
  transform: rotateY(0deg);
}
.contact__list-item p {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  transition: all 0.3s ease-out 0s;
  color: #050707;
}
.contact__list-item p a {
  color: #050707;
}

.contact__form-form {
  background: white;
  padding: 60px 40px;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.08);
}
.contact__form-form-title {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 60px;
}
.contact__form-form-group {
  position: relative;
}
.contact__form-form-group input {
  width: 100%;
  height: 60px;
  border: 1px solid rgba(189, 188, 191, 0.35);
  padding-left: 55px;
}
.contact__form-form-group input:focus {
  border-color: #42b6fe;
}
.contact__form-form-group textarea {
  width: 100%;
  border: 1px solid rgba(189, 188, 191, 0.35);
  padding-left: 55px;
  padding-top: 15px;
}
.contact__form-form-group textarea:focus {
  border-color: #42b6fe;
}
.contact__form-form-group.icon i {
  position: absolute;
  font-size: 16px;
  color: #42b6fe;
  left: 25px;
  top: 20px;
}
.contact__form-form-group button i::before {
  font-size: 16px;
  top: 3px;
  margin-left: 7px;
}
.contact__form-left iframe {
  width: 100%;
  height: 600px;
}

.contact__map iframe {
  width: 100%;
  height: 650px;
  margin-top: -200px;
  margin-bottom: -10px;
}

@media (max-width: 400px) {
  .contact__form-form {
    padding: 40px 30px;
  }
}
/*==========================================================================
Counter
========================================================================== */
.counter__area {
  border-bottom: 1px solid rgba(189, 188, 191, 0.35);
  max-width: 1170px;
  margin: 0 auto;
}
.counter__area-item ul li h2 {
  margin-bottom: 10px;
}
.counter__area-item ul li p {
  font-size: 18px;
  line-height: 28px;
  color: #050707;
}
.counter__area-item ul li i::before {
  width: 70px;
  height: 70px;
  background: #f9f9f9;
  line-height: 75px;
  color: #42b6fe;
  font-size: 35px;
  margin-right: 20px;
  display: inline-block;
  text-align: center;
}

@media (max-width: 1199px) {
  .counter__area-item ul li p {
    font-size: 16px;
    line-height: 26px;
  }
  .counter__area-item ul li i::before {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}
/*==========================================================================
Development 
========================================================================== */
.development__area {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
}
.development__area::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: #050707;
  opacity: 0.8;
}
.development__area-title {
  max-width: 860px;
  margin: 0 auto;
}
.development__area-title h2 {
  color: white;
}

/*==========================================================================
Faq CSS
==========================================================================*/
.faq__area-about {
  padding: 130px 0 325px 0;
  background: #f9f9f9;
}
.faq__area-about-faq {
  padding: 130px 0;
  background: #f9f9f9;
}
.faq__area-image {
  position: absolute;
  right: -150px;
}
.faq__area-collapse {
  background: #f9f9f9;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
  padding: 50px;
}
.faq__area-collapse-item {
  border-left: 3px solid #42b6fe;
  margin-bottom: 20px;
  background: white;
  padding: 0 25px;
}
.faq__area-collapse-item-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 15px 0;
}
.faq__area-collapse-item-card-header h3 {
  font-size: 18px;
  line-height: 28px;
}
.faq__area-collapse-item-card-header i {
  color: #050707;
}
.faq__area-collapse-item-card-header-content {
  padding: 0px 0 20px 0;
}
.faq__area-collapse-item-card-header-content p {
  max-width: 430px;
}
.faq__area-collapse-faq {
  box-shadow: none;
}
.faq__area-content-p p {
  max-width: none;
}

@media (max-width: 1199px) {
  .faq__area-about {
    padding: 130px 0 275px 0;
  }
  .faq__area-collapse {
    padding: 40px;
  }
  .faq__area-collapse-item-card-header h3 {
    font-size: 17px;
    line-height: 27px;
    font-weight: 500;
  }
  .faq__area-collapse-item-card-header-content {
    padding: 0px 0 20px 0;
  }
  .faq__area-collapse-item-card-header-content p {
    font-size: 15px;
  }
}
@media (max-width: 991px) {
  .faq__area-about {
    padding: 130px 0 130px 0;
  }
  .faq__area-image {
    display: none;
  }
}
@media (max-width: 510px) {
  .faq__area-collapse {
    padding: 20px;
  }
  .faq__area-collapse-item {
    padding: 0 20px;
  }
  .faq__area-collapse-item-card-header h3 {
    font-size: 13px;
    line-height: 23px;
    font-weight: 500;
  }
  .faq__area-collapse-item-card-header i {
    font-size: 14px;
  }
  .faq__area-collapse-item-card-header-content p {
    font-size: 13px;
  }
}
/*==========================================================================
Footer
========================================================================== */
.footer__area {
  background: #050707;
}
.footer__area-content p {
  color: white;
  padding: 10px 30px 0 0;
}
.footer__area-logo a img {
  max-width: 135px;
  margin-bottom: 25px;
}
.footer__area-title h3 {
  color: white;
  margin-bottom: 50px;
  position: relative;
}
.footer__area-title h3::before {
  position: absolute;
  content: "";
  height: 2px;
  background: #828187;
  bottom: -25px;
  left: 0;
  width: 150px;
  opacity: 20%;
}
.footer__area-title h3::after {
  position: absolute;
  content: "";
  height: 4px;
  background: #42b6fe;
  bottom: -26px;
  left: 0;
  width: 40px;
}
.footer__area-footer-menu ul li {
  margin-bottom: 15px;
}
.footer__area-footer-menu ul li a {
  color: white;
  transition: 0.4s;
  position: relative;
  left: 0;
}
.footer__area-footer-menu ul li a:hover {
  color: #42b6fe;
  left: 5px;
}
.footer__area-footer-menu ul li:last-child {
  margin: 0;
}
.footer__area-contact-info-item-icon i {
  color: #42b6fe;
  margin-right: 10px;
  font-size: 18px;
}
.footer__area-contact-info-item-text a {
  color: white;
}
.footer__area-bottom {
  background: #050707;
  border-top: 1px solid rgba(111, 111, 111, 0.3098039216);
}
.footer__area-bottom-left p {
  color: white;
}
.footer__area-bottom-left a {
  color: #42b6fe;
  font-weight: 500;
}
.footer__area-bottom-right ul li i {
  margin-right: 15px;
  background: #41404A;
  color: white;
  display: block;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  border-radius: 50%;
  transition: 0.3s;
}
.footer__area-bottom-right ul li i:hover {
  background: #42b6fe;
}
.footer__area-footer-widget form input {
  width: 100%;
  height: 56px;
  background: white;
  padding-left: 25px;
  margin-bottom: 20px;
}
.footer__area-footer-widget form button {
  width: 100%;
}

/*==========================================================================
Copyright
========================================================================== */
.copyright__area {
  position: relative;
  padding: 30px 0;
  background: #111213;
}
.copyright__area-left p {
  color: #9FA0A1;
}
.copyright__area-left p a {
  color: #42b6fe;
}
.copyright__area-right ul li {
  display: inline-block;
}
.copyright__area-right ul li:last-child a {
  padding: 0;
  margin: 0;
  border: 0;
}
.copyright__area-right ul li a {
  color: #9FA0A1;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #9FA0A1;
  transition: all 0.3s ease-in-out;
}
.copyright__area-right ul li a:hover {
  color: #187AD4;
}

@media (max-width: 1199px) {
  .fo-b {
    margin-bottom: 30px;
  }
}
@media (max-width: 991px) {
  .footer__area-contact-info-item {
    display: flex !important;
  }
}
@media (max-width: 767px) {
  .footer__area-bottom-left {
    text-align: center;
  }
  .footer__area-bottom-right {
    text-align: center;
  }
  .copyright__area {
    text-align: center;
  }
  .copyright__area-right {
    text-align: center;
  }
}
/*==========================================================================
Top Bar
========================================================================== */
.header__area-menubar-area-sticky-menu {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  -webkit-animation: header_sticky 1.1s;
          animation: header_sticky 1.1s;
  background: white;
  display: block;
}

@-webkit-keyframes header_sticky {
  0% {
    top: -250px;
  }
  100% {
    top: 0;
  }
}

@keyframes header_sticky {
  0% {
    top: -250px;
  }
  100% {
    top: 0;
  }
}
.header__area-topbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.header__area-topbar-area {
  background: #050707;
  padding: 12px 56px;
}
.header__area-topbar-left {
  display: flex;
  align-items: center;
}
.header__area-topbar-left-info ul li {
  display: inline-block;
  margin-right: 20px;
  list-style: none;
  padding-right: 20px;
  border-right: 1px solid rgba(189, 188, 191, 0.35);
}
.header__area-topbar-left-info ul li:last-child {
  margin: 0;
  padding: 0;
  border: 0;
}
.header__area-topbar-left-info ul li a {
  color: white;
  transition: all 0.4s ease-out 0s;
}
.header__area-topbar-left-info ul li a:hover {
  color: #42b6fe;
}
.header__area-topbar-left-info ul li a i::before {
  color: #42b6fe;
  position: relative;
  top: 2px;
  margin-right: 10px;
}
.header__area-topbar-right {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.header__area-topbar-right-social {
  margin-left: 50px;
  position: relative;
}
.header__area-topbar-right-social::after {
  position: absolute;
  content: "";
  top: -12px;
  left: -25px;
  width: 1px;
  height: 50px;
  background: rgba(189, 188, 191, 0.35);
}
.header__area-topbar-right-social ul li {
  display: inline-block;
  margin-right: 20px;
  list-style: none;
}
.header__area-topbar-right-social ul li:last-child {
  margin: 0;
}
.header__area-topbar-right-social ul li a {
  color: white;
  transition: all 0.4s ease-out 0s;
}
.header__area-topbar-right-social ul li a:hover {
  color: #42b6fe;
}
.header__area-topbar-right-language .nice-select {
  background: none;
  border: 0;
  padding: 0 20px;
  padding-left: 0;
  height: 25px;
  line-height: 25px;
  transition: all 0.4s ease-out 0s;
  color: white;
  font-size: 15px;
}
.header__area-topbar-right-language .nice-select::after {
  width: 8px;
  height: 8px;
  right: 0;
  top: 11px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  transition: all 0.4s ease-out 0s;
}
.header__area-topbar-right-language .nice-select .list {
  background: white;
  margin-top: 12px;
  width: 130px;
  transition: all 0.4s ease-out 0s;
  right: 0;
  z-index: 1000;
  border-radius: 0;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
}
.header__area-topbar-right-language .nice-select .option {
  color: #050707;
  transition: all 0.4s ease-out 0s;
  border-bottom: 1px solid rgba(189, 188, 191, 0.35);
}

@media (max-width: 1785px) {
  .header__area-topbar-area {
    padding: 12px 60px;
  }
}
@media (max-width: 1335px) {
  .header__area-topbar-area {
    padding: 12px 35px;
  }
  .header__area-topbar-left-info ul {
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  .header__area-topbar-area {
    padding: 12px 20px;
  }
}
@media (max-width: 820px) {
  .header__area-topbar {
    display: inline-block;
  }
  .header__area-topbar-area {
    padding-bottom: 10px;
    text-align: center;
  }
  .header__area-topbar-left-info ul li {
    margin: 0;
    padding: 0 10px;
    border: 0;
  }
  .header__area-topbar-right {
    justify-content: center;
  }
  .header__area-topbar-right-social {
    margin-left: 35px;
    position: relative;
  }
  .header__area-topbar-right-social::after {
    position: absolute;
    content: "";
    top: 0;
    left: -17px;
    width: 1px;
    height: 25px;
    background: rgba(189, 188, 191, 0.35);
  }
  .header__area-topbar-right-language .nice-select .list {
    left: 0;
    right: auto;
  }
}
@media (max-width: 575px) {
  .header__area-topbar-area {
    padding: 12px 0;
    padding-bottom: 10px;
  }
}
/*==========================================================================
Menu Bar CSS
==========================================================================*/
.header__area {
  position: relative;
}
.header__area-menubar {
  background: white;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.header__area-menubar-area {
  padding: 0 56px;
}
.header__area-menubar-area .responsive-menu {
  display: none;
}
.header__area-menubar-main-menu {
  margin-left: 242px;
}
.header__area-menubar-main-menu ul li {
  display: inline-block;
  position: relative;
  list-style: none;
}
.header__area-menubar-main-menu ul li:hover > a {
  color: #42b6fe;
}
.header__area-menubar-main-menu ul li:hover > .sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}
.header__area-menubar-main-menu ul li .sub-menu {
  position: absolute;
  background: white;
  min-width: 240px;
  transition: all 0.3s ease-in-out;
  top: 120%;
  opacity: 0;
  visibility: hidden;
  border-top: 2px solid #42b6fe;
  z-index: 99;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.08);
  text-align: left;
}
.header__area-menubar-main-menu ul li .sub-menu li {
  display: block;
  margin: 0;
  border-bottom: 1px solid rgba(189, 188, 191, 0.35);
}
.header__area-menubar-main-menu ul li .sub-menu li a {
  color: #050707;
  padding: 13px 25px;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 700;
}
.header__area-menubar-main-menu ul li .sub-menu li .sub-menu {
  left: 100%;
  top: 0;
}
.header__area-menubar-main-menu ul li .sub-menu li:hover > a {
  color: #42b6fe;
  padding-left: 30px;
}
.header__area-menubar-main-menu ul li .sub-menu li:last-child {
  border: none;
}
.header__area-menubar-main-menu ul li ul .sub-menu li .sub-menu {
  color: #050707;
  cursor: pointer;
}
.header__area-menubar-main-menu ul li a {
  color: #050707;
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  padding: 37px 25px;
  transition: all 0.3s ease-in-out;
  text-transform: capitalize;
}
.header__area-menubar-main-menu ul li.menu-item-has-children > a {
  position: relative;
}
.header__area-menubar-main-menu ul li.menu-item-has-children > a::before {
  content: "";
  position: absolute;
  top: 51%;
  transform: translateY(-50%);
  right: 16px;
  width: 2px;
  height: 8px;
  background: #050707;
  transition: all 0.4s ease;
}
.header__area-menubar-main-menu ul li.menu-item-has-children > a::after {
  content: "";
  position: absolute;
  top: 51%;
  transform: translateY(-50%);
  right: 13px;
  width: 8px;
  height: 2px;
  background: #050707;
}
.header__area-menubar-main-menu ul li.menu-item-has-children:hover > a::before {
  transform: translateY(-50%) rotate(90deg);
  opacity: 0;
}
.header__area-menubar-main-menu ul li.menu-item-has-children:hover > a::after {
  background: #42b6fe;
}
.header__area-menubar-main-menu ul li:last-child a {
  padding-right: 0;
}
.header__area-menubar-main-menu.two ul li:hover > a {
  color: #187AD4;
}
.header__area-menubar-main-menu.two ul li a {
  color: white;
  font-family: "Sarabun", sans-serif;
  display: block;
  font-weight: 600;
  padding: 31px 25px;
  transition: all 0.4s ease-in-out;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 26px;
}
.header__area-menubar-main-menu.two ul li .sub-menu li a::after {
  background: #187AD4;
}
.header__area-menubar-main-menu.two ul li .sub-menu li:hover > a {
  color: #187AD4;
}
.header__area-menubar-main-menu.two ul li ul .sub-menu li .sub-menu {
  color: #187AD4;
}
.header__area-menubar-main-menu.two ul li.menu-item-has-children a::before {
  background: white;
  right: 14px;
}
.header__area-menubar-main-menu.two ul li.menu-item-has-children a::after {
  background: white;
  right: 11px;
}
.header__area-menubar-main-menu.two ul li.menu-item-has-children:hover > a::after {
  background: #187AD4;
}
.header__area-menubar-right {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.header__area-menubar-right-search-icon i {
  cursor: pointer;
  position: relative;
  z-index: 9;
}
.header__area-menubar-right-search-icon i::before {
  display: block;
  color: #050707;
}
.header__area-menubar-right-search-icon.two i::before {
  color: white;
  font-size: 18px;
}
.header__area-menubar-right-search-box {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  left: 0;
  right: 0;
  height: 0;
  background: #050707;
  z-index: 999;
  transition: 0.5s;
  overflow: hidden;
}
.header__area-menubar-right-search-box form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
}
.header__area-menubar-right-search-box input {
  background: white;
  color: #050707;
  border: 0;
}
.header__area-menubar-right-search-box button {
  position: absolute;
  right: 15px;
  top: 19px;
  background-color: transparent;
  text-align: center;
  font-size: 22px;
  color: #42b6fe;
  border: 0;
  outline: none;
  padding: 0;
  margin: 0;
}
.header__area-menubar-right-search-box.active {
  height: 100%;
  top: 0;
}
.header__area-menubar-right-search-box-icon {
  position: absolute;
  right: 50px;
  top: 50px;
  font-size: 22px;
  color: white;
  cursor: pointer;
  transform: rotate(0deg);
}
.header__area-menubar-right-search-box-icon:hover {
  -webkit-animation: rotate 0.4s ease 0s;
          animation: rotate 0.4s ease 0s;
}
.header__area-menubar-right-search-box-icon i {
  cursor: pointer;
  position: relative;
  z-index: 9;
}
.header__area-menubar-right-search-box-icon i::before {
  display: block;
}
.header__area-menubar-right-hamburger {
  margin-left: 25px;
}
.header__area-menubar-right-btn {
  margin-left: 25px;
}
.header__area-menubar-logo a {
  display: inline-block;
}
.header__area-menubar-logo a img {
  max-width: 200px;
  z-index: 999;
  position: relative;
}
.header__area-menubar.two {
  background: transparent;
  position: relative;
}
.header__area-menubar.two::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: white;
  opacity: 0.2;
  z-index: 1;
  height: 1px;
}
.header__area.header__two {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}
.header__area.header__two .header__area-menubar-area-sticky-menu {
  background: #050707;
}

/*==========================================================================
Responsive Menu Css
========================================================================== */
.responsive-menu {
  position: absolute;
  top: -11px;
  right: 0;
  width: 100%;
}

.mean-container a.meanmenu-reveal {
  color: #050707;
  width: 25px;
  height: 31px;
  transition: all 0s ease-out 0s;
}
.mean-container a.meanmenu-reveal span {
  background: #050707;
  margin-top: 6px;
}
.mean-container .mean-bar {
  background: transparent;
  padding: 0;
  z-index: 99;
}
.mean-container .mean-bar a i {
  font-size: 22px;
  padding-top: 4px;
  display: block;
}
.mean-container .mean-nav {
  background: #f9f9f9;
  margin-top: 68px;
}
.mean-container .mean-nav li:first-child > a {
  border: none;
}
.mean-container .mean-nav ul li a.mean-expand {
  background: #42b6fe;
  height: 28px;
  color: white;
}
.mean-container .mean-nav ul li a.mean-expand:hover {
  background: #42b6fe;
}

.mean-container .mean-nav ul li a,
.mean-container .mean-nav ul li li a,
.mean-container .mean-nav li li:first-child > a {
  color: #050707;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  border-top: 1px solid rgba(189, 188, 191, 0.35);
  opacity: 1;
  text-transform: capitalize;
}

/*==========================================================================
Menu Box CSS
==========================================================================*/
.hamburger-popup {
  position: fixed;
  width: 460px;
  height: 100%;
  right: 0;
  overflow: auto;
  transform: translateX(100%);
  top: 0;
  background: white;
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
  transition: all 0.3s ease 0s;
  padding: 80px 50px;
  scrollbar-width: none;
}
.hamburger-popup::-webkit-scrollbar {
  display: none;
}
.hamburger-popup.active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0%);
  right: 0;
}
.hamburger-popup .hamburger-close-btn {
  font-size: 13px;
  position: absolute;
  top: 30px;
  right: 30px;
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  width: 40px;
  color: white;
  height: 40px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.hamburger-popup .hamburger-close-btn img {
  max-width: 15px;
  transform: rotate(0deg);
}
.hamburger-popup .hamburger-close-btn:hover img {
  -webkit-animation: rotate 0.4s ease 0s;
          animation: rotate 0.4s ease 0s;
}
.hamburger-popup-logo {
  margin-bottom: 30px;
}
.hamburger-popup-logo a {
  display: inline-block;
}
.hamburger-popup-logo a img {
  max-width: 155px;
}
.hamburger-popup .menu-box p {
  text-transform: initial;
}

.hamburger-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #050707;
  z-index: 99999;
  opacity: 0.85;
  display: none;
  transition: all 0.4s ease-out 0s;
}
.hamburger-overlay.show {
  display: block;
}

.hamburger-icon.two {
  margin-left: 30px;
}
.hamburger-icon.two i::before {
  color: #187AD4;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  background: #0A1F31;
  font-size: 18px;
}

.hamburger-icon i::before,
.responsive-menu i::before,
.search-icon.open i::before {
  cursor: pointer;
  color: #050707;
}

.contact__info {
  margin: 30px 0;
}
.contact__info-list a {
  display: flex;
  align-items: center;
  color: #828187;
  margin-bottom: 20px;
  transition: all 0.4s ease-out 0s;
}
.contact__info-list a:last-child {
  margin: 0;
}
.contact__info-list a i::before {
  color: #42b6fe;
  font-size: 30px;
  margin-right: 20px;
}
.contact__info-list a:hover {
  color: #42b6fe;
}

.follow__us-social ul li {
  display: inline-block;
  list-style: none;
  margin-right: 10px;
}
.follow__us-social ul li:last-child {
  margin: 0;
}
.follow__us-social ul li a {
  display: inline-block;
}
.follow__us-social ul li a i::before {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: white;
  transition: all 0.4s ease-out 0s;
  text-align: center;
  background: #050707;
  display: block;
  border-radius: 50%;
}
.follow__us-social ul li a:hover i::before {
  background: #42b6fe;
}

@media (max-width: 1740px) {
  .header__area-menubar-area {
    padding: 0 60px;
  }
}
@media (max-width: 1435px) {
  .header__area-menubar-main-menu {
    margin: 0;
  }
  .header__area-menubar-main-menu ul li a {
    padding: 37px 23px;
  }
  .header__area-menubar-area {
    padding: 0 35px;
  }
}
@media (max-width: 1180px) {
  .header__area-menubar-right-btn {
    display: none;
  }
}
@media (max-width: 991px) {
  .header__area-menubar-logo {
    padding-left: 15px;
  }
  .header__area-menubar-area {
    padding: 20px 0;
  }
  .header__area-menubar-area .responsive-menu {
    display: block;
  }
  .header__area-menubar-main-menu-icon > a::before {
    display: none;
  }
  .header__area-menubar-main-menu-icon > a::after {
    display: none;
  }
  .header__area-menubar-right {
    display: none;
  }
  .header__area-menubar.two::after {
    bottom: -20px;
  }
}
@media (max-width: 575px) {
  .header__area-menubar-area {
    padding: 20px 0;
  }
  .mean-container a.meanmenu-reveal {
    margin-right: 4px;
  }
}
/*==========================================================================
Projects Details
========================================================================== */
.project__area__details-left-content h3 {
  padding: 25px 0;
}
.project__area__details-left-content h3 a {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  color: #050707;
}
.project__area__details-left-content p {
  margin-bottom: 25px;
  color: #41404A;
}
.project__area__details-left-content-list {
  border-bottom: 1px solid rgba(189, 188, 191, 0.35);
  margin-bottom: 30px;
}
.project__area__details-left-content-list ul li {
  margin-bottom: 10px;
  color: #41404A;
}
.project__area__details-left-content-list ul li i {
  color: #42b6fe;
  margin-right: 10px;
}
.project__area__details-right-item {
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.08);
  background: white;
  padding: 40px 30px;
}
.project__area__details-right-item-details ul li:last-child {
  margin: 0;
}
.project__area__details-right-item-details ul li {
  margin-bottom: 15px;
}
.project__area__details-right-item-details ul li i {
  color: #42b6fe;
  font-size: 14px;
  margin-right: 7px;
}
.project__area__details-right-item-details ul li span {
  color: #050707;
  font-weight: 500;
  margin-right: 10px;
}
.project__area__details-right-item-details ul li a {
  color: #41404A;
}
.project__area__details-right-item-details-help-contact-icon i::before {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #f9f9f9;
  color: #42b6fe;
  font-size: 22px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin-right: 15px;
}
.project__area__details-right-item-details-help-contact-text a {
  color: #41404A;
}

/*==========================================================================
Projects
========================================================================== */
.project__area {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.project__area-title {
  margin-bottom: 55px;
  max-width: 550px;
}
.project__area-item {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.project__area-item img {
  transform: scale(1);
  transition: 0.3s ease-in-out;
}
.project__area-item::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 20%;
  height: 100%;
  transform: skewX(-25deg);
  background: rgba(255, 255, 255, 0.246);
}
.project__area-item:hover::before {
  -webkit-animation: animation 0.85s;
          animation: animation 0.85s;
}
.project__area-overly-page {
  position: absolute;
  left: 30px;
  bottom: 0px;
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  right: 30px;
  padding: 30px;
  border-radius: 3px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 3;
}
.project__area-overly-page h3 {
  color: white;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 10px;
}
.project__area-overly-page p {
  color: white;
}
.project__area-overly-page-icon a {
  display: block;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}
.project__area-overly-page-icon a i::before {
  background: white;
  height: 40px;
  width: 40px;
  color: #42b6fe;
  display: block;
  line-height: 40px;
  text-align: center;
  border-radius: 2px;
  font-size: 20px;
}
.project__area-overly {
  position: absolute;
  left: 25px;
  bottom: 0px;
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  right: 25px;
  padding: 30px 25px;
  border-radius: 3px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 3;
}
.project__area-overly h3 {
  color: white;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 6px;
}
.project__area-overly p {
  color: white;
}
.project__area-icon a {
  display: block;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}
.project__area-icon a i::before {
  background: white;
  height: 45px;
  width: 45px;
  color: #42b6fe;
  display: block;
  line-height: 45px;
  text-align: center;
  border-radius: 2px;
  font-size: 20px;
}
.project__area .project-slider {
  position: relative;
  margin-right: -400px;
}
.project__area-button {
  text-align: right;
  padding-right: 23px;
}
.project__area-button-next {
  display: inline-block;
  position: static;
  margin: 0;
  margin-left: 40px;
}
.project__area-button-next i::before {
  width: 50px;
  height: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  background: #42b6fe;
  color: white;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
}
.project__area-button-next:hover i::before {
  background: #050707;
  color: white;
}
.project__area-button-next::after {
  display: none;
}
.project__area-button-prev {
  display: inline-block;
  position: static;
  margin: 0;
}
.project__area-button-prev i::before {
  width: 50px;
  height: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  background: #42b6fe;
  color: white;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
}
.project__area-button-prev:hover i::before {
  background: #050707;
  color: white;
}
.project__area-button-prev::after {
  display: none;
}

@media (max-width: 1450px) {
  .project__area-overly h3 {
    font-size: 18px;
    line-height: 28px;
    margin: 0;
  }
  .project__area-icon a i::before {
    height: 30px;
    width: 30px;
    line-height: 30px;
    font-size: 15px;
  }
  .portfolio__area-item img {
    min-height: 420px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media (max-width: 1199px) {
  .xl-mb-30 {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .portfolio__area-item img {
    min-height: initial;
    -o-object-fit: initial;
       object-fit: initial;
  }
}
@media (max-width: 575px) {
  .project__area-title {
    margin-bottom: 55px;
    max-width: 550px;
  }
  .project__area-title h2 {
    color: #050707;
  }
  .portfolio__area {
    padding: 100px 0;
  }
}
@media (max-width: 360px) {
  .project__area-overly {
    bottom: 25px;
    left: 25px;
    right: 25px;
    padding: 30px 25px;
  }
  .project__area-overly h3 {
    font-size: 19px;
    line-height: 29px;
  }
  .project__area-overly-page h3 {
    font-size: 19px;
    line-height: 29px;
  }
  .project__area-overly-page-icon a {
    right: 30px;
  }
  .project__area-overly-page-icon a i::before {
    height: 30px;
    line-height: 30px;
    width: 30px;
    font-size: 18px;
  }
  .project__area-icon a {
    right: 30px;
  }
  .project__area-icon a i::before {
    height: 30px;
    line-height: 30px;
    width: 30px;
    font-size: 18px;
  }
}
.project__area-item-hover .project__area-overly {
  opacity: 1 !important;
  bottom: 25px;
}
.project__area-item-hover .project__area-overly-page {
  opacity: 1;
  bottom: 30px;
}
.project__area-item-hover img {
  transform: scale(1.1);
}

/*==========================================================================
Page Banner
========================================================================== */
.page__banner {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #050707;
  position: relative;
  z-index: 1;
  text-align: center;
  padding-top: 140px;
  padding-bottom: 90px;
}
.page__banner::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #050707;
  opacity: 0.8;
}
.page__banner-content h1 {
  color: white;
  font-size: 45px;
  line-height: 55px;
  font-weight: 800;
}
.page__banner-menu {
  background: white;
  padding: 12px 30px;
  border-radius: 3px;
  position: relative;
  top: 115px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.08);
  text-align: center;
  margin: 0 auto;
  max-width: 300px;
}
.page__banner-menu ul li:last-child {
  color: #42b6fe;
}
.page__banner-menu ul li {
  display: inline-block;
  color: #050707;
  font-weight: 600;
}
.page__banner-menu ul li i::before {
  color: #050707;
  font-size: 20px;
  margin-right: 10px;
  top: 3px;
  transition: all 0.3s ease-out 0s;
  position: relative;
}
.page__banner-menu ul li a {
  color: #050707;
  transition: all 0.3s ease-out 0s;
}
.page__banner-menu ul li a:hover {
  color: #42b6fe;
}
.page__banner-menu ul li a:hover i::before {
  color: #42b6fe;
}

/*==========================================================================
Sponsors
==========================================================================*/
.sponsors__area {
  padding: 39px 0;
}

@media (max-width: 575px) {
  .page__banner-content h1 {
    font-size: 38px;
    line-height: 48px;
    font-weight: 700;
  }
}
/*==========================================================================
Price
========================================================================== */
.pricing__area {
  background: #f9f9f9;
  padding: 240px 0 305px 0;
}
.pricing__area-pricing {
  padding: 130px 0;
  background: #f9f9f9;
}
.pricing__area-services {
  padding: 130px 0;
  background: white;
}
.pricing__area-item {
  background: white;
  padding: 35px 35px;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.08);
}
.pricing__area-item-icon {
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 50%;
  background: #f9f9f9;
  text-align: center;
  line-height: 78px;
  color: #42b6fe;
  margin-right: 20px;
}
.pricing__area-item-icon img {
  max-width: 40px;
  transform: rotateY(0deg);
}
.pricing__area-item:hover img {
  -webkit-animation: rotateY 0.9s ease 0s;
          animation: rotateY 0.9s ease 0s;
}
.pricing__area-price {
  position: relative;
}
.pricing__area-price::before {
  position: absolute;
  content: "";
  bottom: -34px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #DDDDDD;
}
.pricing__area-price ul li h3 {
  font-size: 30px;
  line-height: 40px;
}
.pricing__area-price ul li h5 {
  font-size: 24px;
  line-height: 34px;
  color: #42b6fe;
}
.pricing__area-price ul li span {
  font-size: 14px;
  line-height: 24px;
}
.pricing__area-content ul li {
  color: #41404A;
  margin-bottom: 12px;
}
.pricing__area-content ul li i {
  color: #42b6fe;
  margin-right: 10px;
  font-size: 14px;
  line-height: 24px;
}

@media (max-width: 1199px) {
  .pricing__area-item {
    background: white;
    padding: 30px 25px;
  }
}
/*==========================================================================
Services
========================================================================== */
.services__area {
  background-color: #f9f9f9;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.services__area-item {
  background: white;
  padding: 40px 35px;
  position: relative;
  z-index: 1;
  min-height: 450px;
}
.services__area-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-out 0s;
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  z-index: -1;
  opacity: 0;
}
.services__area-item p {
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(189, 188, 191, 0.35);
  margin-bottom: 25px;
  transition: all 0.4s ease-out 0s;
}
.services__area-item h3 a {
  color: #050707;
  display: inline-block;
  margin: 20px 0 10px 0;
  transition: all 0.4s ease-out 0s;
  font-family: "Livvic", sans-serif;
}
.services__area-item a {
  text-transform: capitalize;
  font-weight: 700;
  font-family: "Mulish", sans-serif;
  color: #050707;
  transition: all 0.4s ease-out 0s;
  display: inline-block;
}
.services__area-item::after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  /* background-image: url("assets/img/services-2.png"); */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 60%;
  height: 150px;
  transition: all 0.4s ease-out 0s;
}
.services__area-item-icon {
  width: 100px;
  height: 100px;
  display: inline-block;
  border-radius: 50%;
  background: #f9f9f9;
  text-align: center;
  line-height: 98px;
  color: #42b6fe;
}
.services__area-item-icon img {
  max-width: 45px;
  transform: rotateY(0deg);
}
.services__area-item:hover::after {
  /* background-image: url("assets/img/white-2.png"); */
}
.services__area-item:hover::before {
  opacity: 1;
}
.services__area-item:hover h3 a {
  color: white;
}
.services__area-item:hover p {
  color: white;
}
.services__area-item:hover a {
  color: white;
}

/*==========================================================================
Services Details
========================================================================== */
.services__details {
  background: #f9f9f9;
}
.services__details-widget-category {
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.08);
  padding: 40px 30px;
  background: white;
}
.services__details-widget-category ul li {
  position: relative;
  list-style: none;
}
.services__details-widget-category ul li::after {
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 100%;
  top: 0;
  bottom: 0;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}
.services__details-widget-category ul li a {
  display: block;
  color: #050707;
  font-weight: 500;
  border: 1px solid rgba(189, 188, 191, 0.35);
  margin-bottom: 15px;
  padding: 16px 20px;
  transition: all 0.4s ease-out 0s;
  position: relative;
  z-index: 2;
}
.services__details-widget-category ul li a i {
  float: right;
  position: relative;
  z-index: 1;
}
.services__details-widget-category ul li a i::before {
  color: white;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  text-align: center;
  font-size: 14px;
  position: relative;
  top: -2px;
  transition: all 0.4s ease-out 0s;
}
.services__details-widget-category ul li:last-child a {
  margin: 0;
}
.services__details-widget-category ul li:hover::after {
  width: 100%;
}
.services__details-widget-category ul li:hover a {
  color: white;
}
.services__details-widget-category ul li:hover a i::before {
  background: white;
  color: #42b6fe;
}
.services__details-widget-hours {
  padding: 40px 30px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.services__details-widget-hours::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #050707;
  opacity: 0.8;
}
.services__details-widget-hours h3 {
  color: white;
  position: relative;
  z-index: 2;
}
.services__details-widget-hours ul {
  position: relative;
  z-index: 2;
}
.services__details-widget-hours ul li {
  color: white;
  padding: 5px;
}
.services__details-widget-hours ul li p {
  float: right;
  color: white;
}
.services__details-content h4 a {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  color: #050707;
}
.services__details-content p {
  margin-bottom: 25px;
  color: #41404A;
}
.services__details-content ul li {
  margin-bottom: 10px;
  color: #41404A;
}
.services__details-content ul li i {
  color: #42b6fe;
  margin-right: 10px;
}
.services__details-services h4 {
  position: relative;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  border-top: 1px solid rgba(189, 188, 191, 0.35);
}
.services__details-counter {
  padding: 30px 30px 5px 30px;
  background: white;
}

@media (max-width: 427px) {
  .services__area-item-icon {
    width: 90px;
    height: 90px;
    line-height: 88px;
  }
  .services__area-item-icon img {
    max-width: 40px;
  }
  .services__area-item h3 {
    font-size: 20px;
    line-height: 30px;
  }
}
/*==========================================================================
Skill 
========================================================================== */
.skill__area {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
}
.skill__area::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: #050707;
  opacity: 0.8;
}
.skill__area-title {
  max-width: 560px;
}
.skill__area-title h2 {
  color: white;
}
.skill__area-video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  width: 25px;
  height: 26px;
  display: block;
  border-radius: 50%;
  padding: 0 0 10px 5px;
}
.skill__area-video-icon::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 100px;
  display: block;
  top: 50%;
  left: 50%;
  z-index: 0;
  background: white;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-animation: pulse-border 1500ms ease-out infinite;
          animation: pulse-border 1500ms ease-out infinite;
}
.skill__area-video-icon::after {
  position: absolute;
  content: "";
  background: white;
  top: 50%;
  left: 50%;
  display: block;
  z-index: 1;
  transform: translateX(-50%) translateY(-50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  transition: all 200ms;
}
.skill__area-video-icon span {
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 24px solid #42b6fe;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  display: block;
}

.skilbar__area-box {
  background: white;
  padding: 50px;
  top: 110px;
  left: 0;
}
.skilbar__area-content {
  position: relative;
}
.skilbar__area-content h5 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}
.skilbar__area-count {
  position: absolute;
  top: 0;
  right: 0;
}
.skilbar__area-inner {
  width: 100%;
  height: 10px;
  background: #f9f9f9;
  margin-top: 10px;
  position: relative;
  border-radius: 5px 0 0 5px;
}
.skilbar__area-bar {
  height: 10px;
  position: absolute;
  width: 0;
  top: 0px;
  left: 0px;
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  transition: 3.5s;
  border-radius: 5px 0 0 5px;
}

@media (max-width: 767px) {
  .skill__area-video-icon {
    position: inherit;
    margin: 50px 0px -65px 50px;
  }
}
/*==========================================================================
Team
========================================================================== */
.team__area-team-content {
  padding: 25px 30px;
  background: white;
  margin-top: -30px;
  position: relative;
  z-index: 3;
  transition: all 0.4s ease-out 0s;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.08);
}
.team__area-about {
  padding: 240px 0 130px 0;
}
.team__area-image-img {
  position: relative;
  overflow: hidden;
}
.team__area-image-img::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 20%;
  height: 100%;
  transform: skewX(-25deg);
  background: rgba(255, 255, 255, 0.246);
}
.team__area-item:hover .team__area-icon {
  opacity: 1;
  left: 20px;
}
.team__area-item:hover .team__area-image-img::before {
  -webkit-animation: animation 0.85s;
          animation: animation 0.85s;
}
.team__area-item:hover .team__area-content::before {
  opacity: 1;
}
.team__area-item:hover .team__area-content h3 {
  color: white;
}
.team__area-item:hover .team__area-content p {
  color: white;
}
.team__area-item:hover .team__area-team-content {
  background: #42b6fe;
}
.team__area-item:hover .team__area-team-content h3 {
  color: white;
}
.team__area-item:hover .team__area-team-content p {
  color: white;
}
.team__area-icon {
  position: absolute;
  top: 20px;
  left: 0px;
  opacity: 0;
  transition: 0.6s;
  z-index: 10;
}
.team__area-icon ul li i::before {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: white;
  background: #42b6fe;
  transition: 0.6s;
  margin-bottom: 10px;
}
.team__area-icon ul li i:hover::before {
  background: white;
  color: #050707;
}
.team__area-content {
  padding: 25px 30px;
  background: white;
  margin-top: -30px;
  position: relative;
  z-index: 3;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.08);
}
.team__area-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-out 0s;
  background: linear-gradient(270deg, #17ddfe 0%, #42b6fe 100%);
  z-index: -1;
  opacity: 0;
}
.team__area-content p {
  color: #41404A;
  transition: all 0.4s ease-out 0s;
}
.team__area-content h3 {
  transition: all 0.4s ease-out 0s;
}

@media (max-width: 1199px) {
  .team__area {
    padding: 130px 0 100px 0;
  }
  .team__area-content {
    padding: 20px 14px;
  }
  .team__area-content h3 {
    font-size: 22px;
    line-height: 32px;
  }
}
@media (max-width: 991px) {
  .team__area {
    padding: 130px 0 100px 0;
  }
}
@media (max-width: 767px) {
  .team__area {
    padding: 130px 0 100px 0;
  }
  .team__area-team {
    padding: 130px 0 180px 0;
  }
}
/*==========================================================================
Testimonial
========================================================================== */
.testimonial__area-mt-175 {
  margin-top: -175px;
}
.testimonial__area-bg {
  background: #0a0e2f;
  padding: 60px;
  padding-right: 0;
}
.testimonial__area-image::after {
  position: absolute;
  content: "";
  border: 3px solid #42b6fe;
  width: 140px;
  height: 345px;
  left: 45px;
  bottom: -55px;
  transform: rotate(36deg);
  border-radius: 80px;
  z-index: -1;
  opacity: 60%;
}
.testimonial__area-image img {
  max-width: 230px;
  height: auto;
  border-radius: 50%;
}
.testimonial__area-content {
  max-width: 550px;
}
.testimonial__area-content :first-child {
  color: white;
  font-size: 16px;
}
.testimonial__area-content p {
  font-size: 15px;
  color: #42b6fe;
}
.testimonial__area-content h5 {
  font-weight: 700;
  color: white;
}
.testimonial__area-content i {
  position: absolute;
  right: 25%;
  bottom: 0;
  font-size: 40px;
  color: #42b6fe;
  opacity: 60%;
}

.pagination-area {
  position: absolute;
  right: 50px;
  top: 33%;
  z-index: 2;
}
.pagination-area::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 10px;
  bottom: 40%;
  left: 2px;
  background: #050707;
  z-index: 2;
}
.pagination-area .dots span {
  display: list-item;
  height: 60px;
  width: 5px;
  background: white;
  position: relative;
  opacity: 20%;
  border-radius: 0;
  transition: 0.5s;
}

.pagination-area .dots span.swiper-pagination-bullet-active {
  height: 60px;
  width: 5px;
  background: #42b6fe;
  opacity: 100%;
  border-radius: 0;
}

@media (max-width: 1199px) {
  .testimonial__area-image::after {
    width: 130px;
    height: 290px;
    left: 30px;
    bottom: -45px;
  }
}
@media (max-width: 991px) {
  .testimonial__area-bg {
    padding: 30px;
  }
  .testimonial__area-image::after {
    width: 100px;
    height: 210px;
    left: 15px;
    bottom: -35px;
  }
  .pagination-area {
    display: none;
  }
}
@media (max-width: 767px) {
  .testimonial__area-bg {
    padding: 30px;
  }
  .testimonial__area-image::after {
    display: none;
  }
}
@media (max-width: 575px) {
  .testimonial__area-bg {
    text-align: center;
  }
}/*# sourceMappingURL=style.css.map */

.choose__ua-left {
    background-image: url('assets/img/choose-us.jpg');
}

.section-padding {
    background-image: url('assets/img/choose.png')   
}
.banner__area {
    background-image: url('assets/img/banner.jpg')   
}
.skill__area {
    background-image: url('assets/img/skill.jpg');
}
.page__banner {
    background-image: url('assets/img/page-banner.png');
}